import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/Auth/login/login.component';
import { LoginFrameComponent } from './components/Auth/login-frame/login-frame.component';
import { RegisterComponent } from './components/Auth/register/register.component';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { VerifyCodeComponent } from './components/Auth/verify-code/verify-code.component';
import { GameComponent } from './components/game/game.component';
import { PurchaseGalleryComponent } from './components/purchase-gallery/purchase-gallery.component';
import { AdditionalPurchaseInfoComponent } from './components/additional-purchase-info/additional-purchase-info.component';
import { ConfigLoaderService } from './services/config-loader-service.service';
import { PreloadFactory } from './services/pre-load-factory';

const ngZorroConfig: NzConfig = {
  message: { nzTop: 200 }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LoginFrameComponent,
    RegisterComponent,
    VerifyCodeComponent,
    GameComponent,
    PurchaseGalleryComponent,
    AdditionalPurchaseInfoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    ScrollingModule,
    DragDropModule,
    AppRoutingModule,
    AuthModule.forRoot()
  ],
  providers: [
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    },
    ConfigLoaderService,
    {
      provide: APP_INITIALIZER,
      deps: [
        ConfigLoaderService
      ],
      multi: true,
      useFactory: PreloadFactory
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
