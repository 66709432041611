<div nz-row nzJustify="center" nzAlign="middle" style="height: 100%;">
  <div nz-col>
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()" style=" margin-top: 0px !important;">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <nz-input-group nzPrefixIcon="user">
            <input type="text" nz-input formControlName="userName" placeholder="Username" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" [nzLoading]="isLoggingIn" *ngIf="!isLogginError">Log in</button>
      <button nz-button class="login-form-button login-form-margin" nzType="default" nzDanger *ngIf="isLogginError" (click)="clearError()">Incorrect user / pass</button>
    </form>
  </div>
</div>

